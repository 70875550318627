import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageMaterielAstrophotographie: allPagesCompilationYaml(
      filter: { code: { eq: "materielAstrophotographie" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["logicielsAstrophotographie", "testEOSRa", "galerieVoieLactee"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["materielNGC7000", "ic4592ChevalAustral"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["ic4628Crevette"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielSaturne"
            "materielUnemontureEquatorialeOrionAZ-EQG"
            "materielUnelunetteSkywatcher80ED"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielUnecameraZWOASI294"
            "materielTelescopeCelestron11(C11)"
            "materielUnecameraZWOASI290MC"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: { code: { in: ["materielUnemontureEquatorialeStarAdventurer"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function Materielastrophotographie() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageMaterielAstrophotographie")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageTresPetit",
    "imagesPortraitPetit"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Entre les images d’Hubble et celles d’astrophotographes reconnus qui
          circulent sur Internet, l’annonce de nouvelles découvertes, vous êtes
          de plus en plus nombreux à vous intéresser à l’astrophotographie. Pour
          passer enfin à la pratique et assouvir votre passion, l’achat de votre
          matériel astrophotographique est la première marche à franchir.
        </p>
        <p>
          Mais mieux vaut ne pas vous tromper : le bon instrument n’est ni le
          plus cher, ni le plus gros, mais celui qui est souvent utilisé. Et
          depuis l’arrivée de constructeurs chinois sur le marché il y a environ
          20 ans, l’offre disponible est devenue pléthorique. Il est assez
          difficile pour une personne non avertie de s’y retrouver. Pour vous
          aider dans votre choix et réussir vos débuts, voici ce guide.
        </p>
        <Image
          image={obtenirImage(images, "ic4592ChevalAustral")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Un équipement pour chaque type d’objet">
        <p>
          Votre nouvel équipement astrophotographique{" "}
          <strong>
            ne vous permettra pas de réaliser tous les types d’images
          </strong>
          . Comme en photographie diurne, n’espérez pas faire de la photo
          animalière avec un objectif grand angle. C’est exactement la même
          chose en astrophotographie car il existe une multitude d’objets dans
          le ciel. Des petits qui nécessiteront beaucoup de focale, des très
          grands qui demanderont moins de focale, des lointains qui demanderont
          beaucoup d’heures de poses, des plus proches très brillants qui
          nécessiteront des caméras ultra rapides, etc. Quelle que soit la
          discipline choisie, le budget nécessaire pour pratiquer
          l’astrophotographie est important.
        </p>
        <p>
          Deux grands domaines astrophotographiques existent :
          l’astrophotographie <strong>« planétaire »</strong> pour photographier
          la Lune et les planètes, et l’astrophotographie du{" "}
          <strong>« ciel profond »</strong>, pour photographier les objets peu
          brillants situés au-delà du système solaire (nébuleuses, galaxies…).
        </p>
      </Section>
      <Section titre="Principe">
        <p>
          L’ennemi de l’astrophotographe est le bruit. Nous l’avons tous
          constaté : quand on réalise des photographies en faible luminosité, la
          qualité des images est mauvaise. Elles sont dégradées par le bruit.
          Pour le diminuer, la technique employée en astrophotographie est
          toujours la même : on accumule des poses dites{" "}
          <strong>« unitaires »</strong>, d’une durée plus ou moins longue, qui
          sont ensuite additionnées sur ordinateur pour simuler une pose d’une
          durée plus importante, moins bruitée.
        </p>
      </Section>
      <Section titre="L’astrophotographie planétaire">
        <p>
          L’astrophotographie planétaire vous permettra de photographier les
          objets <strong>de notre système Solaire</strong> : la Lune, Saturne et
          ses anneaux, Jupiter… Mais attention, pas le Soleil qui nécessite un
          matériel particulier. Comme pour l’observation visuelle,
          l’astrophotographie planétaire nécessite un instrument de bon
          diamètre, au moins 200 mm pour obtenir de bons résultats.
        </p>
        <p>
          En astrophotographie planétaire, l’ennemi n°1 est la{" "}
          <strong>turbulence</strong> produite par l’atmosphère qui nous
          entoure. Comme quand vous regardez par une fenêtre située au-dessus
          d’un radiateur un après-midi d’hiver : le paysage situé en
          arrière-plan est troublé par l’air chaud qui monte au-dessus du
          radiateur. L’atmosphère terrestre produit les mêmes effets sur les
          images qui nous arrivent du ciel.
        </p>
        <p>
          Mais contrairement aux turbulences engendrées par un radiateur, il
          peut arriver que{" "}
          <strong>l’atmosphère se calme pendant de brefs instants.</strong>{" "}
          Pendant ce trou de turbulence, les images deviennent nettes. Il faut
          alors profiter de cet instant pour acquérir un maximum d’images. La
          technique est d’utiliser une caméra ultra rapide (certaines peuvent
          prendre jusqu’à 60 images par seconde !) et de filmer la planète
          pendant quelques minutes. Un logiciel analysera ensuite le film et
          sélectionnera les meilleures images qui auront été prises pendant les
          trous de turbulence. Ces images seront ensuite additionnées pour
          diminuer le bruit de l’image finale.
        </p>
        <p>
          Les sujets photographiés étant très lumineux et les temps d’exposition
          très brefs, la pollution lumineuse n’est en général pas un gros
          problème en astrophotographie planétaire. Certains astrophotographes
          obtiennent même d’excellents résultats depuis leur balcon d’immeuble…
        </p>
        <Image
          image={obtenirImage(images, "materielSaturne")}
          langue={page.langue}
        />
        <h3>Le matériel nécessaire</h3>
        <p>
          L’astrophotographie planétaire nécessite un télescope de{" "}
          <strong>bon diamètre</strong> et de longueur focale suffisante (au
          moins <strong>800 mm</strong>). Les télescopes de type Newton seront
          les moins chers, mais ils sont encombrants : la longueur du tube sera
          sensiblement égale à la longueur de focale de l’instrument.{" "}
          <strong>Comptez 300 €</strong> pour un bon premier prix. N’oubliez
          surtout pas l’indispensable laser de collimation qui vous permettra
          d’aligner les miroirs de votre télescope ainsi qu’une lentille de
          barlow qui permettra d’augmenter si besoin la longueur focale de votre
          instrument.
        </p>
        <p>
          <strong>Si votre budget est plus important</strong>, vous pouvez jeter
          un œil sur les télescopes <strong>Schmidt-Cassegrain</strong> ou
          encore les télescopes de type <strong>Maksutov</strong>, du nom de
          leurs formules optiques. Ces instruments ont l’avantage d’être plus{" "}
          <strong>compacts</strong> que les télescopes Newton{" "}
          <strong>pour une longueur focale supérieure</strong>. Leur poids sera
          en revanche similaire. Comptez environ 800 € pour un tube seul.
          Regardez ce que font les marques Celestron, Skywatcher, ou encore
          Orion (le matériel de ces 3 marques est identique car provenant du
          même fabricant chinois Synta, seul le look change) qui sont des
          marques sûres pour débuter.
        </p>
        <p>
          Une fois votre télescope acheté, il vous faudra vous procurer{" "}
          <strong>une monture équatoriale suffisamment dimensionnée</strong>{" "}
          pour le supporter. Son rôle sera de suivre le déplacement de la voûte
          céleste. Comptez de 600 à 2&nbsp;000 € pour une monture de qualité.
          Renseignez-vous auprès de votre magasin pour réaliser le meilleur
          choix.
        </p>
        <p>
          Il vous reste enfin à vous procurer le matériel informatique : un
          ordinateur portable si vous n’en avez pas, et une caméra dite «
          planétaire » (un modèle refroidi n’est pas nécessaire). Pour débuter,
          choisissez une caméra couleur. Les utilisateurs confirmés utilisent
          des caméras monochromes, plus sensibles, et permettant collecter des
          données scientifiques à l’aide de filtres spécialisés.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "materielTelescopeCelestron11(C11)")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materielUnecameraZWOASI290MC")}
            langue={page.langue}
          />
        </div>
        <p>
          Comptez de 200 € à 300 € pour une caméra couleur de qualité.
          Aujourd’hui, les caméras ASI du fabricant chinois ZWO sont ce qui ce
          fait de mieux sur le marché et sont très distribuées en Europe. Et
          bien sûr, n’oubliez pas une batterie pour votre monture (50 €). Au
          total, prévoyez entre 2&nbsp;500 et 3&nbsp;000 € pour vous lancer dans
          l’imagerie planétaire.
        </p>
      </Section>
      <Section titre="L’astrophotographie du ciel profond">
        <p>
          On entend par ciel profond tous les objets qui se trouvent en dehors
          du système solaire : nébuleuses, galaxies, amas d’étoiles…
          Contrairement aux planètes de notre système solaire, les objets du
          ciel profond sont très peu brillants. Pour les photographier,{" "}
          <strong>il faut cumuler de longues heures de poses</strong>, alors
          qu’en imagerie planétaire, il faut réaliser des poses ultra-courtes.
          La taille des objets photographiés diffère aussi : ils sont souvent
          très grands (parfois plusieurs fois la taille de la lune). Vous
          l’aurez compris, cette discipline exige des matériels complètement
          différents.
        </p>
        <Image
          image={obtenirImage(images, "ic4628Crevette")}
          langue={page.langue}
          legende="IC 4628, la nébuleuse de la Crevette, prise à l’aide d’une lunette Borg 101 ED et d’une caméra CCD Atik 460 EX."
        />
        <h3>Le matériel optique</h3>
        <p>
          Vous pouvez utiliser n’importe quel type d’optique disposant de
          suffisamment de focale (au moins 200 ou 300 mm) : un téléobjectif, un
          télescope, une lunette… Contrairement à l’astrophotographie
          planétaire, le diamètre de l’instrument importe peu. Un instrument de
          60 mm de diamètre peut être ainsi suffisant.
        </p>
        <p>
          Pour débuter, les lunettes sont idéales : elles sont faciles
          d’utilisation (pas de collimation à réaliser), peu encombrantes et
          légères. Une lunette apochromatique (dépourvue de chromatisme) de type
          « <strong>80 ED</strong> » munie de son{" "}
          <strong>correcteur optique</strong> (afin d’obtenir des étoiles rondes
          dans les angles des images) est idéale pour débuter. Ces lunettes sont
          peu onéreuses (environ 400 €), et en général optiquement très bonnes.
          Comptez environ 200 € pour le correcteur. Pour commencer, regardez ce
          que font les marques chinoises comme Orion ou Skywatcher (le fabricant
          est le même : Synta).
        </p>
        <p>
          Le prix des lunettes haut de gamme spécialisées dans
          l’astrophotographie peut aussi vite s’envoler, certaines dépassent
          largement la barre des 10&nbsp;000 €.
        </p>
        <Image
          image={obtenirImage(images, "materielUnelunetteSkywatcher80ED")}
          langue={page.langue}
        />
        <p>
          Les lunettes excellent pour imager les grands objets (nébuleuses). Si
          vous souhaitez vous attaquer aux galaxies, beaucoup plus petites, il
          vous faudra plus de focale. Vous pouvez alors vous orienter vers un
          télescope. Mais à cause de leur encombrement, de leur longueur focale
          importante, et des réglages nécessaires (collimation) les télescopes
          seront plus difficiles à utiliser en astrophotographie. Comptez de 500
          € pour un télescope de type newton (200 mm de diamètre), jusqu’à plus
          de 10&nbsp;000, 20&nbsp;000, 30&nbsp;000 € (ou plus encore) pour les
          télescopes les plus haut de gamme.
        </p>
        <h3>L’imageur</h3>
        <p>
          Vous pouvez utiliser <strong>votre réflex</strong> que vous
          connecterez directement à votre instrument à l’aide d’une bague
          d’adaptation (demandez des informations à votre revendeur). Le but
          sera de prendre des poses « unitaires » de quelques minutes pendant
          quelques heures. Elles seront ensuite additionnées sur ordinateur pour
          simuler une pose plus longue. Une télécommande sera alors nécessaire
          pour lancer les poses à distance et éviter les vibrations. Ou mieux,
          un intervallomètre (pour programmer une séquence de poses, 30 €), ou
          un PC qui sera relié à votre appareil.
        </p>
        <p>
          <strong>Les réflex sont idéaux pour débuter</strong>. Mais pour des
          utilisateurs exigeants, ils ont deux problèmes : ils ne sont pas assez
          sensibles, et les images qu’ils délivrent peuvent être (cela dépend
          des modèles) de mauvaise qualité dans le noir : elles sont bruitées.
          Il faut alors s’orienter vers une caméra spécialisée (CMOS maintenant
          ou CCD jusqu’à un passé récent). Ce sont des caméras équipées d’un
          capteur couleur ou noir et blanc, très sensibles et très peu bruitées.
          Elles sont aussi refroidies afin, entre autres, de diminuer le bruit
          des images prises. Certaines peuvent ainsi descendre à 60° sous la
          température ambiante. Le prix de ces caméras dépend de la technologie
          utilisée et de la taille de leur capteur. Il reste néanmoins élevé :
          de 1&nbsp;200 € environ, jusqu’à plus de 10&nbsp;000 € pour des
          caméras à capteur carré 36 mm * 36 mm.
        </p>
        <Image
          image={obtenirImage(images, "materielUnecameraZWOASI294")}
          langue={page.langue}
        />
        <p>
          Si vous choisissez de vous orienter vers un réflex pour débuter, il
          vous faudra le faire modifier pour imager les nébuleuses. L’opération
          consiste à ouvrir l’appareil et à en changer la « vitre » de
          protection du capteur. En effet, celle-ci coupe une grande partie du
          rouge intense émis par l’hydrogène des nébuleuses (c’est un filtre
          passe-bas). Certaines sociétés sont spécialisées dans l’opération
          comme la société{" "}
          <Lien urlExterne="https://www.eosforastro.com/">EOS for Astro</Lien>.
        </p>
        <Image
          image={obtenirImage(images, "materielNGC7000")}
          langue={page.langue}
        />
        <h3>La monture équatoriale</h3>
        <p>
          La <strong>qualité</strong> de la monture est{" "}
          <strong>primordiale</strong> en astrophotographie ciel profond.
          Pendant les longues heures de poses, son rôle{" "}
          <strong>est de compenser le déplacement de la voûte céleste</strong>{" "}
          lié à la rotation de la Terre afin de faire en sorte que chaque pixel
          du capteur de votre caméra ou appareil photo reste en face de la même
          portion de ciel. Si le suivi de votre monture n’est pas bon, les
          étoiles de votre image ne seront plus rondes mais ovales. Ce défaut
          sera d’autant plus visible que la focale de votre optique sera
          importante.
        </p>
        <p>
          Ces problèmes de suivi peuvent-être corrigés avec un dispositif{" "}
          <strong>d’autoguidage</strong> : il sera nécessaire d’acheter une
          petite lunette (ou un diviseur optique) sur laquelle sera branchée une
          caméra qui filmera en permanence une étoile. Cette image sera analysée
          par un ordinateur, qui, en cas de mauvais suivi, enverra une
          correction à votre monture. Attention, l’autoguidage est complexe à
          maîtriser pour les débutants et cette dépense n’est pas utile pour
          commencer (réalisez des poses de quelques dizaines de secondes).
          Demandez conseil à votre revendeur.
        </p>
        <p>
          <strong>
            La monture doit être adaptée à la taille de votre optique
          </strong>{" "}
          : un gros télescope nécessitera une grosse monture. Si vous commencez
          par une petite lunette, mais prévoyez d’utiliser un télescope plus
          tard, choisissez une monture avec une capacité de charge adaptée au
          poids de votre futur télescope. Évitez les montures de type
          altazimutales : un mouvement sur les 2 axes haut et bas est effectué
          pour réaliser le suivi, ce qui engendre des problèmes de rotation de
          champ. Préférez les montures « équatoriales » car le suivi est réalisé
          sur un seul axe : celui de rotation de la Terre.
        </p>
        <p>
          Le prix d’une bonne monture (de type EQM-35 PRO, HEQ5 ou EQ6 chez
          Skywatcher) varie de 700 € jusqu’à plus de 15&nbsp;000 € pour les
          montures les plus précises capables de supporter les plus gros
          instruments.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "materielUnemontureEquatorialeOrionAZ-EQG"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "materielUnemontureEquatorialeStarAdventurer"
            )}
            langue={page.langue}
          />
        </div>
        <p>
          Au total (instrument, correcteur, caméra ou appareil photo, bagues,
          rallonges, monture, batteries, etc.), pour photographier le ciel
          profond, comptez un budget de 3&nbsp;000 € environ, jusqu’à… un budget
          presque infini.
        </p>
      </Section>
      <Section titre="Clubs et marché de l’occasion">
        <p>
          <strong>N’oubliez pas le marché de l’occasion</strong>, sur lequel
          vous pouvez vous procurer des instruments à des tarifs très
          intéressants (v. liens utiles plus bas dans cette page). Si vous vous
          orientez vers cette solution, faites-vous accompagner d’une personne
          avertie.
        </p>
        <p>
          Derniers conseils, n’achetez pas tout d’un coup. Achetez votre
          matériel au fur et à mesure de vos besoins. Et surtout, adressez-vous
          au club d’astronomie près de chez vous. Vous pourrez tester du
          matériel avant de l’acheter, et vous bénéficierez de conseils avisés
          d’amateurs déjà passionnés et équipés depuis très longtemps.
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
